import React from 'react'
import styled from '@emotion/styled'
// import Layout from '../containers/Layout'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';

import ballon_1x from '../images/home/baloon.png'
import ballon_2x from '../images/home/baloon@2x.png'
import ballon_3x from '../images/home/baloon@3x.png'

import confetti_1x from '../images/home/confetti.png'
import confetti_2x from '../images/home/confetti@2x.png'
import confetti_3x from '../images/home/confetti@3x.png'

import dog_1x from '../images/home/dog-door.png'
import dog_2x from '../images/home/dog-door@2x.png'
import dog_3x from '../images/home/dog-door@3x.png'

import hand_1x from '../images/home/hand-eye.png'
import hand_2x from '../images/home/hand-eye@2x.png'
import hand_3x from '../images/home/hand-eye@3x.png'

import heilandskirche_1x from '../images/home/heilandskirche.png'
import heilandskirche_2x from '../images/home/heilandskirche@2x.png'
import heilandskirche_3x from '../images/home/heilandskirche@3x.png'

import coffee_1x from '../images/home/man-coffee-ice-cream.png'
import coffee_2x from '../images/home/man-coffee-ice-cream@2x.png'
import coffee_3x from '../images/home/man-coffee-ice-cream@3x.png'

import map_1x from '../images/home/map.png'
import map_2x from '../images/home/map@2x.png'
import map_3x from '../images/home/map@3x.png'

import markthalle_1x from '../images/home/markthalle.png'
import markthalle_2x from '../images/home/markthalle@2x.png'
import markthalle_3x from '../images/home/markthalle@3x.png'

import park_1x from '../images/home/park.png'
import park_2x from '../images/home/park@2x.png'
import park_3x from '../images/home/park@3x.png'

import woman_1x from '../images/home/snapping-woman.png'
import woman_2x from '../images/home/snapping-woman@2x.png'
import woman_3x from '../images/home/snapping-woman@3x.png'

import man_1x from '../images/home/sporty-man.png'
import man_2x from '../images/home/sporty-man@2x.png'
import man_3x from '../images/home/sporty-man@3x.png'

import trumpet_1x from '../images/home/woman-trumpet.png'
import trumpet_2x from '../images/home/woman-trumpet@2x.png'
import trumpet_3x from '../images/home/woman-trumpet@3x.png'

import arrow_material from '../images/other/arrow_forward_ios-24px.svg'
import arrow_bold from '../images/other/arrow.svg'
import arrow_cta from '../images/other/cta-arrow.svg'
import arrow from '../images/other/next.svg'
import { setIsMapView, setIsCategoryView } from '../state/actions'



const Home = (props) => {
  const { 
    setIsMapView,
    setIsCategoryView
  } = props;


  const Wrapper = styled.div`
    position: relative;
    top: -85px;
    width: 100%;
    @media(min-width: 768px){
      top: -96px;
      width: 100%;
    }
  `

  const Markthalle = styled.div`
    position: absolute;
    z-index: 1;
    background-image: url(${markthalle_3x}); 
    background-image: image-set(url(${markthalle_1x}) 1x, url(${markthalle_2x}) 2x,  url(${markthalle_3x}) 3x);
    background-size: contain;
    width: calc(127 / 375 * 100vw);
    top: calc(3 / 697 * 100vh + 80px);
    right: 0px;

    background-position: center right;
    background-repeat: no-repeat;

    &::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(102 / 127 * 100%);
      }
    
      .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      @media(min-width: 768px){
        width: calc(404 / 1440 * 100vw);
        top: calc(150 / 1440 * 100vh + 80px);
        &::before{
          padding-top: calc(341 / 404 * 100%);
        }
      }
  `

  const SportyMan = styled.div`
  position: absolute;
  z-index: 2;
  background-image: url(${man_3x}); 
  // background-image: image-set(url(${man_1x}) 1x, url(${man_2x}) 2x,  url(${man_3x}) 3x);
  background-size: contain;
  width: calc(102 / 375 * 100vw);

  top: calc(109 / 697 * 100vh + 80px);
  right: calc(61 / 375 * 100vw);

  background-position: bottom right;
  background-repeat: no-repeat;

  &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(102 / 102 * 100%);
    }
  
    .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    @media(min-width: 768px){
      width: calc(155 / 1440 * 100vw);
      top: calc(157 / 810 * 100vh);
      left: calc(486 / 1440 * 100vw);
      &::before{
        padding-top: calc(177 / 155 * 100%);
      }
    }

`


  const HandEye = styled.div`
position: absolute;
z-index: 2;
background-image: url(${hand_3x}); 
// background-image: image-set(url(${hand_1x}) 1x, url(${hand_2x}) 2x,  url(${hand_3x}) 3x);
background-size: contain;
width: calc(148 / 375 * 100vw);
top: calc(26 / 697 * 100vh + 80px);
left: 0;

background-position: center left;
background-repeat: no-repeat;

&::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(166 / 148 * 100%);
  }

  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media(min-width: 768px){
    width: calc(306 / 1440 * 100vw);
    top: calc(254 / 810 * 100vh);
    left: 0;
    &::before{
      padding-top: calc(440 / 306 * 100%);
    }
  }
`

  const Heilandskreuz = styled.div`
position: absolute;
z-index: 1;
background-image: url(${heilandskirche_3x}); 
// background-image: image-set(url(${heilandskirche_1x}) 1x, url(${heilandskirche_2x}) 2x,  url(${heilandskirche_3x}) 3x);
background-size: contain;
width: calc(91 / 375 * 100vw);
top: calc(152 / 697 * 100vh + 80px);
left: calc(28 / 375 * 100vw);

background-position: top right;
background-repeat: no-repeat;

&::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(262 / 91 * 100%);
  }

  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media(min-width: 768px){
    width: calc(207 / 1440 * 100vw);
    top: calc(102 / 810 * 100vh);
    left: calc(182 / 1440 * 100vw);
    &::before{
      padding-top: calc(593 / 207 * 100%);
    }
  }
`


  const Coffee = styled.div`
position: absolute;
z-index: 2;
background-image: url(${coffee_3x}); 
// background-image: image-set(url(${coffee_1x}) 1x, url(${coffee_2x}) 2x,  url(${coffee_3x}) 3x);
background-size: contain;
width: calc(134 / 375 * 100vw);
top: calc(445 / 697 * 100vh + 80px);
left: calc(28 / 375 * 100vw);

background-position: center right;
background-repeat: no-repeat;

&::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(161 / 134 * 100%);
  }

  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media(min-width: 768px){
    width: calc(254 / 1440 * 100vw);
    top: calc(546 / 810 * 100vh);
    left: calc(341 / 1440 * 100vw);
    &::before{
      padding-top: calc(305 / 254 * 100%);
    }
  }
`
  const Woman = styled.div`
position: absolute;
z-index: 2;
background-image: url(${woman_3x}); 
// background-image: image-set(url(${woman_1x}) 1x, url(${woman_2x}) 2x,  url(${woman_3x}) 3x);
background-size: contain;
width: calc(162 / 375 * 100vw);
top: calc(400 / 697 * 100vh + 80px);
right: calc(16 / 375 * 100vw);

background-position: center right;
background-repeat: no-repeat;

&::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(251 / 162 * 100%);
  }

  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media(min-width: 768px){
    width: calc(368 / 1440 * 100vw);
    top: calc(480 / 810 * 100vh);
    left: calc(759 / 1440 * 100vw);
    &::before{
      padding-top: calc(571 / 368 * 100%);
    }
  }
`


  const Trumpet = styled.div`
display: none;
@media(min-width: 768px){
  display: block;
  position: absolute;
  z-index: 2;
  background-image: url(${trumpet_3x}); 
  // background-image: image-set(url(${trumpet_1x}) 1x, url(${trumpet_3x}) 2x,  url(${trumpet_3x}) 3x);
  background-size: contain;
  width: calc(739 / 1440 * 100vw);
  top: 0;
  right: calc(53 / 1440 * 100vw);
  
  background-position: center right;
  background-repeat: no-repeat;
  
  &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(755 / 739 * 100%);
    }
  
    .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`

  const Balloon = styled.div`
display: none;
@media(min-width: 768px){
  display: block;
  position: absolute;
  z-index: 2;
  background-image: url(${ballon_3x}); 
  // background-image: image-set(url(${ballon_1x}) 1x, url(${ballon_2x}) 2x,  url(${ballon_3x}) 3x);
  background-size: contain;
  width: calc(67 / 1440 * 100vw);
  top: calc(143 / 810 * 100vh);
  right: calc(462/ 1440 * 100vw);

  background-position: center right;
  background-repeat: no-repeat;

  &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(194 / 67 * 100%);
    }

    .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`
  const Park = styled.div`
display: none;
@media(min-width: 768px){
  display: block;
  position: absolute;
  z-index: 2;
  background-image: url(${park_3x}); 
  // background-image: image-set(url(${park_1x}) 1x, url(${park_2x}) 2x,  url(${park_3x}) 3x);
  background-size: contain;
  width: calc(107 / 1440 * 100vw);
  top: calc(926 / 810 * 100vh);
  right: calc(113 / 1440 * 100vw);

  background-position: center right;
  background-repeat: no-repeat;

  &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(134 / 107 * 100%);
    }

    .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`
  const Dog = styled.div`
display: none;
@media(min-width: 768px){
  display: block;
  position: absolute;
  z-index: 2;
  background-image: url(${dog_3x}); 
  // background-image: image-set(url(${dog_1x}) 1x, url(${dog_2x}) 2x,  url(${dog_3x}) 3x);
  background-size: contain;
  // width: calc(107 / 1440 * 100vw);
  // top: calc(926 / 810 * 100vh);
  // right: calc(113 / 1440 * 100vw);
  width: 320px;


  background-position: center right;
  background-repeat: no-repeat;
  margin-top: 36px;
  &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc(291 / 320 * 100%);
    }

    .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`


  const Map = styled.div`
position: absolute;
z-index: 1;
background-image: url(${map_1x}); 
// background-image: image-set(url(${map_1x}) 1x, url(${map_2x}) 2x,  url(${map_3x}) 3x);
background-size: contain;
width: calc(296 / 375 * 100vw);
top: calc(536 / 697 * 100vh + 80px);
right: 0;

background-position: center right;
background-repeat: no-repeat;

&::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(215 / 296 * 100%);
  }

  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media(min-width: 768px){
    width: calc(1032 / 1440 * 100vw);
    top: calc(672 / 810 * 100vh);
    right: 0;
    &::before{
      padding-top: calc(707 / 1032 * 100%);
    }
  }
`


  const MainHeadline = styled.h1`
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  top: calc(207 / 697 * 100vh + 80px);
  font-family: Rockwell;
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  width: 100%;
  @media(min-width: 768px){
    font-size: 72px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    top: calc(325 / 810 * 100vh);
  }
`

  const SubHeadline = styled.h2`
margin: 0;
position: absolute;
z-index: 3;
top: calc(253 / 697 * 100vh + 80px);
font-family: SofiaW01;
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.44;
letter-spacing: normal;
text-align: center;
width: calc(245 / 375 * 100vw);
left: 50%;
transform: translateX(-50%);

@media(min-width: 768px){
  top: calc(407 / 810 * 100vh);
  font-size: 24px;
  line-height: 1.5;
}
`

  const SubSubHeadline = styled.h3`
  font-family: Rockwell;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left; 
  padding: 0 24px 0 0;
  width: calc(327 / 375 * 100vw);
  margin-top: calc(664 / 697 * 100vh );
  position: relative;
  z-index: 3;
  @media(min-width: 768px){
    position: static;
    margin-top: 0;
    font-size: 36px;
    line-height: 1.28;
    // width: calc(361 / 1440 * 100vw);
    width: 361px;
    padding: 0;
  }
`
  const HomeContent = styled.div`
  position: relative;
  z-index: 3;
  padding: 0 24px;
  @media(min-width: 768px){
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    margin-top: calc(900 / 810 * 100vh );
    margin-top: clamp()
  }
`

  const ButtonMore = styled(GatsbyLink)`
z-index: 4;
border-radius: 6px;
box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);
background-color: ${({ theme }) => theme.mi_red};
text-decoration: none;
color: ${({ theme }) => theme.mi_white};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  padding: 12px 32px 14px 14px;
  background-image: url(${arrow_bold});
  background-size: 7px 13px;
  background-position: right 12px center;
  top: calc(329 / 697 * 100vh + 80px);
  text-transform: uppercase;
  background-size: 14px;
  background-repeat: no-repeat;
  white-space: nowrap;
  @media(min-width: 768px){
    top: calc(485 / 810 * 100vh);

  }
`
  const ButtonCallToAction= styled(GatsbyLink)`
z-index: 3;
border-radius: 6px;
box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);
background-color: ${({ theme }) => theme.mi_white};
color: ${({ theme }) => theme.mi_black};

font-family: SofiaW01;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
text-align: center;
display: flex;
justify-content: center;
padding: 24px;
margin: 36px 24px 36px 0;
white-space: nowrap;
width: 100%;
text-decoration: none;
.mi-arrow-cta{
  display: block;
  width: 18px;
  height: 10px;
  align-self: center;
  background-image: url(${arrow_cta});
  background-size: contain;
  background-repeat: no-repeat;

  align-self: flex-end;
  margin-bottom: 5px;
  margin-left: 8px;
}
@media(min-width: 768px){
  max-width: 302px;
  margin-left: 0;
  margin-top: 36px;
  margin-bottom: 100px;
}
`


  const Left = styled.div`
@media(min-width: 768px){
  flex: 5 0 41.66%;
}`
  const Right = styled.div`
@media(min-width: 768px){
  flex: 7 0 56%;
}`

  // background-image: image-set(url(${markthalle_1x}) 1x, url(${markthalle_2x}) 2x),url(${markthalle_3x}) 3x); 
  // &::before {
  //   display: block;
  //   content: "";
  //   width: 100%;
  //   padding-top: calc(102 / 127 * 100%);
  // }

  // .inner {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  // }



  return (
    <Wrapper>
      <Markthalle><div className="inner" /></Markthalle>
      <SportyMan><div className="inner" /></SportyMan>
      <HandEye><div className="inner" /></HandEye>
      <Heilandskreuz><div className="inner" /></Heilandskreuz>
      <Coffee><div className="inner" /></Coffee>
      <Woman><div className="inner" /></Woman>
      <Map><div className="inner" /></Map>
      <Trumpet><div className="inner" /></Trumpet>
      <Balloon><div className="inner" /></Balloon>
      <Park><div className="inner" /></Park>

      
      <MainHeadline>Die Moabiter Insel</MainHeadline>
      <SubHeadline>Entdecke deine Nachbarschaft in Moabit</SubHeadline>
      <ButtonMore to='/kiezfest/' title='Erfahre mehr'>Erfahre mehr</ButtonMore>

      <HomeContent>
        <Left>
          <SubSubHeadline>Das Moabiter Kiezfest 2020 geht online!</SubSubHeadline>
          <Dog><div className="inner" /></Dog>
        </Left>
        <Right>
          <p>Seit 2013 feiern lokale Akteur_innen aus den Bereichen Gastronomie, Kunsthandwerk, Gewerbe und Kultur und Soziales das Moabiter Kiezfest, ein beliebtes Stadtteilfest rund um das Rathaus Tiergarten. Hier präsentieren die Teilnehmenden auf einem Markt und in einem abwechselungsreichen Bühnenprogramm den zahlreichen Besucher_innenihre Projekte, Ideen und Produkte.</p>
          <p>Auf Grund der Maßnahmen zur Eindämmung des Covid19-Virus kann das Moabiter Kiezfest 2020 nicht in gewohnter Form stattfinden. Dennoch wollen wir auch in diesem Jahr gemeinsam das bunte Gesicht Moabits nach außen tragen und den lokalen Akteur_innen die Möglichkeit geben mit Interessierten und den Bewohner_innen in Kontakt zu treten.</p>
          <p>Mit der Webseite moabiterinsel.de geht das Moabiter Kiezfest online und vereint die lokalen Akteur_innen im Netz: Auf einer interaktiven Karte können Sie den Kiez erkunden, die diversen Akteur_innen kennenlernen und mithilfe von persönlichen Portraits mehr über Ihr Lieblingscafe oder -schneider erfahren!</p>
          <p>Wir wünschen viel Spaß beim Erkunden!</p>
          <p>
            <ButtonCallToAction
              title="Zur Karte"
              to='/erkunden/'
              onClick={() => {
                setIsCategoryView(true)
                setIsMapView(true)}}
            >
              Entdecke alle Akteure_innen 
              <span className="mi-arrow-cta" />
            </ButtonCallToAction>
          </p>
        </Right>
      </HomeContent>


    </Wrapper>
  )
}

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};



export default connect(null, mapDispatchToProps)(Home)